import './assets/scss/common.scss';
import './assets/scss/mobile.scss';
import './assets/scss/transition.scss';

import Vue from 'vue';
import axios from 'axios';

import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://api.doppelsoft.net' : 'https://api.test.doppelsoft.net';
Vue.prototype.$http = axios;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
