<style lang="less" scoped>
</style>

<template lang="pug">
.wrap
  article.container
    .content
      section.section_box
        div.section_box_inwrap
          div.section_box_title
            h2.heading_title 해시태그
          div.hash_tag_wrap
            transition-group.hash_tag_ul(
              name="transition-list",
              tag="ul",
            )
              li.hash_tag_item(
                v-for="(hashtag, index) in filteredHashtags",
                :key="hashtag",
              )
                button.hash_tag_button(
                  :class="selectedHashtag === hashtag ? 'is-active' : ''",
                  @click="selectHashtag(index)",
                )
                  span {{ hashtag }}
              li.hash_tag_item(v-if="hasMore", key="more")
                button.btn_more_list(@click="toggleMore")
                  span 더보기
      section.section_box
        div.section_box_inwrap
          div.section_box_title
            h2.heading_title 장소
          div.thumbnail_list_wrap
            ul.thumbnail_list_ul
              template(v-for="place in filteredPlaces")
                li.thumbnail_list_item(v-if="place === 'ad'", key="ad")
                  div.thumbnail_list_ad(id="div-gpt-ad-1692863952877-0")
                  p(data-ke-size="size10")
                li.thumbnail_list_item(v-else, :key="place._id")
                  a.thumbnail_list_link.type_place(
                    :href="generatePlaceLink(place)",
                    target="_blank",
                  )
                    dl.thumbnail_list_info
                      dt.place_info
                        sup.place_sup(v-if="place.subtitle") {{ place.subtitle }}
                        p.place_tit
                          strong.place_name {{ place.name }}
                          em.place_category(v-if="place.category") {{ place.category.name }}
                      dd.place_pos
                        span.place_space_unit(v-if="calcDist(place.gps.coordinates)").
                          {{ calcDist(place.gps.coordinates) }}
                        address.place_address {{ place.address }}
                      dd.place_tag
                        button.place_tag_txt(
                          v-for="h in place.hashtag",
                          :key="h",
                          type="button",
                        ) \#{{h | displayHashTagWithoutEmoji}}
                    figure.thumbnail_list_img(v-if="place.thumbnail")
                      a(
                        :href="generatePlaceImgLink(place)",
                        target="_blank",
                      )
                        img(:src="place.thumbnail", :alt="place.name")
</template>

<script>
const MAX_HASHTAG_LENGTH = 5;

function distance(lat1, lon1, lat2, lon2) {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((lat2 - lat1) * p) / 2
    + c(lat1 * p) * ((c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2);

  return 12742 * Math.asin(Math.sqrt(a)) * 1000; // 2 * R; R = 6371 km
}

export default {
  name: 'BannerList',
  filters: {
    displayHashTagWithoutEmoji(hashtag) {
      if (!hashtag) return '';
      return hashtag.replace(/([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?/g, '');
    },
  },
  computed: {
    hasMore() {
      if (!this.hashtags) return false;
      if (this.moreVisible) return false;
      return this.hashtags.length >= MAX_HASHTAG_LENGTH;
    },
    filteredHashtags() {
      if (!this.hashtags) return [];
      if (this.moreVisible) return this.hashtags;
      if (this.hashtags.length < MAX_HASHTAG_LENGTH) return this.hashtags;
      const sorted = [...this.hashtags].sort((a) => {
        const isHidden = this.hashtags.slice(MAX_HASHTAG_LENGTH).includes(a);
        if (isHidden && a === this.selectedHashtag) return -1;
        return 1;
      });
      const filtered = sorted.slice(0, MAX_HASHTAG_LENGTH);
      return filtered;
    },
    filteredPlaces() {
      if (!this.places) return [];
      if (this.places.length > 2) return [...this.places.slice(0, 2), 'ad', ...this.places.slice(2)];
      return [...this.places, 'ad'];
    },
  },
  data() {
    return {
      hashtags: [],
      places: [],
      os: '',
      selectedHashtag: '',
      moreVisible: false,
    };
  },
  async mounted() {
    this.checkDeviceOS();
    this.setAds();
    const { data: hashtagsData } = await this.$http.get('/cms-public-place-hashtags');
    this.hashtags = hashtagsData;
    this.setInitialSelectedHashtag();
    this.fetchPlaces();
  },
  methods: {
    generatePlaceLink(place) {
      const encodingInAppLink = encodeURIComponent(`smartermove://findRoute?endLat=${place.gps.coordinates[1]}&endLng=${place.gps.coordinates[0]}&endName=${encodeURIComponent(place.name)}&placeId=${place._id}&type=half`);
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      let redirectUrlPath;

      if (!isMobile) redirectUrlPath = 'https://blog.doppelsoft.net/2122';
      else redirectUrlPath = 'https://doppelsoft.tistory.com';

      return `https://smartermove.page.link/?link=${redirectUrlPath}?${encodingInAppLink}&apn=teamdoppelganger.smartermove&isi=6443533129&ibi=com.doppelsoft.smartermove`;
    },
    generatePlaceImgLink(place) {
      const encodingInAppLink = encodeURIComponent(`smartermove://findRoute?endLat=${place.gps.coordinates[1]}&endLng=${place.gps.coordinates[0]}&endName=${encodeURIComponent(place.name)}&placeId=${place._id}&type=full`);
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      let redirectUrlPath;

      if (!isMobile) redirectUrlPath = 'https://blog.doppelsoft.net/2122';
      else redirectUrlPath = 'https://doppelsoft.tistory.com';

      return `https://smartermove.page.link/?link=${redirectUrlPath}?${encodingInAppLink}&apn=teamdoppelganger.smartermove&isi=6443533129&ibi=com.doppelsoft.smartermove`;
    },
    toggleMore() {
      this.moreVisible = !this.moreVisible;
    },
    setAds() {
      window.googletag.cmd.push(() => {
        window.googletag.display('div-gpt-ad-1692863952877-0');
      });
    },
    refreshAds() {
      window.googletag.cmd.push(() => {
        window.googletag.pubads().refresh();
      });
    },
    checkDeviceOS() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) this.os = 'android';
      else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) this.os = 'ios';
      else this.os = 'all';
    },
    async fetchPlaces() {
      const { data: placeData } = await this.$http.get('/cms-public-places', {
        params: {
          hashtag: this.selectedHashtag,
          limit: '1000',
        },
      });
      this.refreshAds();
      if (!!this.$route.query.lat && !!this.$route.query.lng) {
        this.places = placeData.sort((a, b) => {
          if (!a.gps.coordinates || !b.gps.coordinates) return 0;
          const [aLng, aLat] = a.gps.coordinates;
          const aDist = distance(
            this.$route.query.lat,
            this.$route.query.lng,
            aLat,
            aLng,
          );
          const [bLng, bLat] = b.gps.coordinates;
          const bDist = distance(
            this.$route.query.lat,
            this.$route.query.lng,
            bLat,
            bLng,
          );
          if (aDist <= bDist) return -1;
          return 1;
        });
      } else {
        this.places = placeData.sort((a, b) => {
          if (a.name > b.name) return 1;
          return -1;
        });
      }
    },
    setInitialSelectedHashtag() {
      if (this.hashtags.length < 1) return;
      const paramHashtag = this.$route.query.hashtag;
      if (!paramHashtag) {
        this.selectedHashtag = this.hashtags[0];
        return;
      }
      const targetHashtag = this.hashtags.find((h) => h === paramHashtag);
      if (!targetHashtag) [this.selectedHashtag] = this.hashtags;
      else this.selectedHashtag = targetHashtag;
    },
    selectHashtag(index) {
      this.selectedHashtag = this.filteredHashtags[index];
      if (this.moreVisible) this.moreVisible = false;
      this.fetchPlaces();
    },
    calcDist(coord) {
      if (!this.$route.query.lat || !this.$route.query.lng) return null;
      const [lng, lat] = coord;
      const d = distance(
        this.$route.query.lat,
        this.$route.query.lng,
        lat,
        lng,
      );
      if (d < 1000) return `${d.toFixed(0)}m`;
      return `${(d / 1000).toFixed(2)}km`;
    },
  },
};
</script>
